@font-face {
  font-family: 'Minecraft';
  src: local('Minecraft.ttf') format('tff');
}
canvas {
  margin: auto 0;
  width: 100%;
  height: 100%;
}
#progress-container {
  position: absolute;
  top: 50vh;
  width: 100%;
  margin: 0 auto;
  /* height: 1px; */
}
#progress-text {
  text-align: center;
}
#progress-bar {
  height: 1px;
}
#loading-overlay {
  height: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Minecraft';
  color: whitesmoke;
}
#game-container {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
body {
  margin: 0;
  background-color: black;
  display: flex;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
